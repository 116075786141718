// Spinner.js
import React from "react";
import './styles.css';

const Spinner = ({type = 'light'}: {type?: 'dark' | 'light'}) => {
    return (<div
        className={`spinner w-5 h-5 border-2 border-solid rounded-full border-black/10 ${
            type === 'dark' ? 'border-l-[#501A46]' : 'border-l-[#F2E4EF]'
        } `}
    />);
};

export default Spinner;
