var jalaali = require('jalaali-js')

export const extractAndConvertDateToShamsi = (input: string): string => {
    try {
        if (!input || input.length === 0) return input;
        const date = new Date(input);
        const shamsiDate = new Intl.DateTimeFormat('fa-IR', {
            calendar: 'persian',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).format(date);
        return shamsiDate;
    } catch (error) {
        console.error(error);
        return 'error';
    }
}

export const extractAndConvertTimeToShamsi = (input: string): string => {
    try {
        if (!input || input.length === 0) return input;

        const date = new Date(input);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            console.error("Invalid date input:", input);
            return "Invalid Date";
        }

        const options: Intl.DateTimeFormatOptions = {
            calendar: 'persian',
            timeStyle: 'short', // Only include the time (hours and minutes)
        };
        const shamsiTime = new Intl.DateTimeFormat('fa-IR', options).format(date);
        return shamsiTime;
    } catch (error) {
        console.error(error);
        return 'error'
    }

};

export const combineDateAndTime = (dateStr: string, timeStr: string | undefined): string => {
    try {
        if (!timeStr) return '';

        // Parse the date in the "YYYY-MM-DD" format (Milady/Gregorian)
        const date = new Date(dateStr);

        // Split time in HH:mm:ss format
        const [hours, minutes] = timeStr?.split(":").map(Number);

        // Adjust the time to Iran Standard Time (UTC+3:30)
        date.setUTCHours(hours, minutes, 0, 0); // Iran Time to UTC // Do not convert to utc because all data are utc from backend

        // Return the string in ISO 8601 format
        return date.toISOString();
    } catch (error) {
        console.error(error);
        return 'error';
    }
};

export const convertPersianToEnglishDigits = (persianString: string) => {
    const persianDigits = '۰۱۲۳۴۵۶۷۸۹';
    const englishDigits = '0123456789';

    return persianString.split('').map(char => {
        const index = persianDigits.indexOf(char);
        return index === -1 ? char : englishDigits[index];
    }).join('');
}

export const convertJalaliToGregorian = (jalaliDate: string) => {
    // Split the Persian date string into year, month, and day
    const [jalaliYear, jalaliMonth, jalaliDay] = jalaliDate.split("/").map(Number);

    // Convert Jalali to Gregorian using jalaali-js
    const {gy, gm, gd} = jalaali.toGregorian(jalaliYear, jalaliMonth, jalaliDay);

    // Return the Gregorian date in YYYY-MM-DD format
    return `${gy}-${String(gm).padStart(2, '0')}-${String(gd).padStart(2, '0')}`;
}
