import React, { useState } from 'react';

type Option = {
    key: number;
    value: string;
};

interface RadioButtonGroupProps {
    options: Option[];
    onSelect: (value: string) => void;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ options, onSelect }) => {
    const [selectedOption, setSelectedOption] = useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setSelectedOption(newValue);
        onSelect(newValue);
    };

    return (
        <div className="flex flex-col space-y-2 w-full">
            {options.map((option) => (
                <label key={option.key} className="flex items-center gap-2">
                    <input
                        type="radio"
                        name="options"
                        value={option.value}
                        checked={selectedOption === option.value}
                        onChange={handleChange}
                        className="form-radio text-blue-600 w-5 h-5"
                    />
                    <span className="text-gray-700">{option.value}</span>
                </label>
            ))}
        </div>
    );
};

export default RadioButtonGroup;
