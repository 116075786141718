import {useLazyGetDataQuery} from "../api/services/apiSlice";
import {useGlobalContext} from "../contexts/GlobalContext";
import {useState} from "react";

export default function useInfo() {
    const [getApi, responseGetApi] = useLazyGetDataQuery();

    const {setInformation} = useGlobalContext();
    const [aboutUsInformation, setAboutUsInformation] = useState<AboutUsInformationType>();

    const getInformation = async (slug: string) => {
        try {
            let response = await getApi({
                url: `info?slug=${slug}`,
            });

            if (response?.isSuccess) {
                setInformation(response?.data)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getAboutUsInformation = async (slug: string) => {
        try {
            let response = await getApi({
                url: `about?slug=${slug}`,
            });

            if (response?.isSuccess) {
                setAboutUsInformation(response?.data)
            }
        } catch (error) {
            console.error(error);
        }
    }

    return {
        getInformation,
        responseGetApi,
        getAboutUsInformation,
        aboutUsInformation,
    };
}
