import {useLazyGetDataQuery, gatewayAPI} from "../api/services/apiSlice";
import {useDispatch} from "react-redux";

export default function useTimeSlots() {
    const [getApi, responseGetApi] = useLazyGetDataQuery();
    const dispatch = useDispatch();

    const getTimeSlots = async (slug: string, day: string, serviceIds: string) => {
        try {
            let response = await getApi({
                url: `timeslots?slug=${slug}&day=${day}&serviceIds=${serviceIds}`,
            });

            if (response?.isSuccess) {
                dispatch(gatewayAPI.util.resetApiState());
                return {status: true, data: response?.data};
            }
            return {status: false, data: null};
        } catch (error) {
            console.error(error);
            return {status: false, data: null};
        }
    }

    return {
        getTimeSlots,
        responseGetApi,
    };
}
