import React from 'react';
import Button from "../../../atomic/button";
import ErrorResult from "../../../../assets/images/cancel-booking-error-result.svg";
import SuccessResult from "../../../../assets/images/cancel-booking-success-result.svg";
import BookingInformationCard from "../../../composite/booking-information-card";
import {InfoIcon} from "../../../../assets/icons";

const CancelReservationResultModal = ({onCloseModal, selectedBookingToCancel, cancelBookingResult}: {
    onCloseModal: () => void;
    selectedBookingToCancel: BookingType | undefined;
    cancelBookingResult: boolean;
}) => {

    return (
        <>
            <div className={'flex flex-col items-center text-right my-auto gap-4 p-5 pt-20'}>
                <img src={cancelBookingResult ? SuccessResult : ErrorResult} alt={'result'}/>
                <div
                    className={`flex flex-col gap-2 w-full items-center ${cancelBookingResult ? 'text-green' : 'text-red'}`}
                >
                    <p className={'text-right'}>{`${selectedBookingToCancel?.name} عزیز!`}</p>
                    <p className={'text-right'}>
                        {cancelBookingResult ? 'نوبت شما با موفقیت لغو شد' : 'لغو نوبت شما با خطا مواجه شد'}
                    </p>
                </div>

                <BookingInformationCard mode={'canceling'} resultStatus={true} data={selectedBookingToCancel}/>

                {cancelBookingResult && selectedBookingToCancel?.needPay &&
                    <div className={'flex flex-col rounded gap-2 px-3 py-2 bg-purple-superLight w-full'}>
                        <div className={'flex gap-2 items-center'}>
                            <InfoIcon color={"#92395C"}/>
                            <p className={'font-bold text-[#92395C]'}>
                                توجه
                            </p>
                        </div>
                        <p className={'text-sm'}>
                            <span className={'font-bold'}>
                                جهت بازگشت وجه،{' '}
                            </span>
                            کد نوبت
                            <span className={'font-bold'}>
                                {' '}“{selectedBookingToCancel?.id}”{' '}
                            </span>
                            را به شماره{' '}
                            <span className={'font-bold text-[#92395C]'}>
                                {process.env.REACT_APP_TARGET_PHONE_NUMBER}
                            </span>
                            {' '}پیامک کنید
                        </p>
                    </div>
                }
            </div>
            <div className={'flex justify-center items-center text-right gap-4 bg-gray-100 w-full py-4'}>
                <Button
                    title={'بستن'}
                    onClick={() => onCloseModal()}
                    variety={'secondary'}
                    customClassName={'h-[4vh]'}
                />
            </div>
        </>
    )
}

export default CancelReservationResultModal;
