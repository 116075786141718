export const replacePath = (pathname: string, replaceValue: string, numberOfPop: number): string => {
    let pathSegments = pathname.split('/').filter(Boolean); // Remove any empty segments (e.g., from trailing slashes)

    // Pop the specified number of segments
    pathSegments = pathSegments.slice(0, -numberOfPop);

    // Add the new value
    pathSegments.push(replaceValue);

    return '/' + pathSegments.join('/');
};
