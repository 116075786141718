import React from 'react';

const InfoItemCard = ({children}: { children: React.ReactNode }) => {
    return (
        <div className={'flex w-full px-4 py-2 bg-purple-superLight rounded'}>
            {children}
        </div>
    )
}
export default InfoItemCard;
