import React, {useEffect} from 'react';
import Title from "../../atomic/title";
import Layout from "../../composite/layout";
import {useGlobalContext} from "../../../contexts/GlobalContext";
import {AboutUsIcon, PlusIcon, TelegramLogo} from "../../../assets/icons";
import Button from "../../atomic/button";
import BookedServiceCard from "../../composite/booked-service-card";
import FloatingButton from "../../atomic/floating-button";
import Modal from "../../composite/modal/Modal";
import CancelReservationRequestModal from "../../screen/modals/cancel-reservation-request-modal";
import {useNavigate, useParams} from "react-router-dom";
import CancelReservationResultModal from "../../screen/modals/cancel-reservation-result-modal";
import useBooking from "../../../hooks/useBooking";
import Loading from "../../atomic/loading";
import AboutUsModal from "../../screen/modals/about-us-modal";

const MyAppointmentBooking = () => {
    const navigate = useNavigate();
    const {slug} = useParams();
    const {getBookingInformation, cancelBooking, responsePutApi, responseGetApi} = useBooking();
    const {bookingInformation} = useGlobalContext();

    const [displayCancelBookingRequestModal, setDisplayCancelBookingRequestModal] = React.useState(false);
    const [displayCancelBookingResultModal, setDisplayCancelBookingResultModal] = React.useState(false);
    const [selectedBookingToCancel, setSelectedBookingToCancel] = React.useState<BookingType>();
    const [cancelBookingResult, setCancelBookingResult] = React.useState<boolean>(false);
    const [displayAboutUsModal, setDisplayAboutUsModal] = React.useState(false);

    const onClickCancelBookingRequestButton = async (selected_booking: BookingType, selectedReason: string) => {
        try {
            let result = await cancelBooking(selected_booking, slug, selectedReason);

            setCancelBookingResult(result);
            setDisplayCancelBookingRequestModal(false);
            setDisplayCancelBookingResultModal(true);

            result && updateBookingInformation();

        } catch (error) {
            console.error(error);
        }
    }

    const updateBookingInformation = () => {
        try {
            let phoneNumber = localStorage?.getItem('phoneNumber');
            if (!phoneNumber || !slug) {
                navigate('/')
            } else {
                getBookingInformation(slug, phoneNumber);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (!bookingInformation) {
            updateBookingInformation();
        }
    }, [bookingInformation]);

    useEffect(() => {
        updateBookingInformation();
    }, []);

    return (
        <Layout>
            <div className={'flex flex-col text-right my-auto gap-4'}>
                <div className={'flex flex-col gap-6 justify-items-start'}>
                    <div className={'flex justify-between items-center'}>
                        <Title text="نوبت‌های من"/>
                        <Button
                            title={'درباره ما'}
                            onClick={() => setDisplayAboutUsModal(true)}
                            rightIcon={<AboutUsIcon/>}
                            variety={'secondary'}
                            customClassName={'!min-w-10 min-h-fit'}
                        />
                    </div>
                    <Button
                        title="نوبت جدید"
                        rightIcon={<PlusIcon/>}
                        onClick={() => navigate(`/${slug}/appointment-booking`)}
                    />
                    <div className={'flex flex-col gap-6 overflow-y-scroll h-[70vh] scroll'}>
                        {responseGetApi?.isLoading ?
                            <div className={'flex flex-col gap-1 w-full justify-center items-center h-full'}>
                                <Loading type={'dark'}/>
                                <p className={'text-purple-dark text-center text-sm'}>در حال بارگزاری...</p>
                            </div> :
                            bookingInformation?.bookings?.map(item =>
                                <BookedServiceCard
                                    key={item.id}
                                    onClickCancelReservationButton={() => {
                                        setDisplayCancelBookingRequestModal(true);
                                        setSelectedBookingToCancel(item);
                                    }}
                                    data={item}
                                />)
                        }
                    </div>
                </div>
                <FloatingButton
                    onClick={() => {
                        window.open(process.env.REACT_APP_TELEGRAM_LINK, '_blank');
                    }}
                    icon={<div className={'bg-[#E1EFFF] p-2 rounded-full shadow-xl'}><TelegramLogo/></div>}
                />

                <Modal isOpen={displayCancelBookingRequestModal}
                       onClose={() => setDisplayCancelBookingRequestModal(false)}>
                    <CancelReservationRequestModal
                        onCloseModal={() => setDisplayCancelBookingRequestModal(false)}
                        selectedBookingToCancel={selectedBookingToCancel}
                        onClickCancelBookingRequestButton={(selectedBookingToCancel: BookingType, selectedReason: string) => onClickCancelBookingRequestButton(selectedBookingToCancel, selectedReason)}
                        loading={responsePutApi?.isLoading}
                    />
                </Modal>

                <Modal isOpen={displayCancelBookingResultModal}
                       onClose={() => setDisplayCancelBookingResultModal(false)}>
                    <CancelReservationResultModal
                        onCloseModal={() => setDisplayCancelBookingResultModal(false)}
                        selectedBookingToCancel={selectedBookingToCancel}
                        cancelBookingResult={cancelBookingResult}
                    />
                </Modal>

                <Modal
                    isOpen={displayAboutUsModal}
                    onClose={() => setDisplayAboutUsModal(false)}>
                    <AboutUsModal
                        onCloseModal={() => setDisplayAboutUsModal(false)}
                    />
                </Modal>
            </div>
        </Layout>
    )
}

export default MyAppointmentBooking;
