import React, { useEffect } from 'react';
import Layout from "../../composite/layout";

const Landing = () => {
    // Disable parent body scrolling
    useEffect(() => {
        // Hide the body scroll when the component is mounted
        document.body.style.overflow = 'hidden';

        // Restore the scroll behavior when the component is unmounted
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <Layout>
            <iframe
                src={'/landing-page.html'}
                title="Landing Page"
                width="100%"
                height="100%"
                style={{
                    border: 'none',
                    position: 'fixed',  // Make the iframe fixed to occupy the full screen
                    top: 45,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    height: '100vh',  // Full height of the viewport
                    overflow: 'auto',  // Ensure scrolling inside the iframe
                }}
            />
        </Layout>
    );
};

export default Landing;
