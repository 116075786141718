import React, {useEffect, useState} from 'react';
import Layout from "../../composite/layout";
import Button from "../../atomic/button";
import {LeftArrowIcon, TelegramLogo} from "../../../assets/icons";
import Input from "../../atomic/input";
import useInfo from "../../../hooks/useInfo";
import {useNavigate, useParams} from "react-router-dom";
import useBooking from "../../../hooks/useBooking";
import {isIranianPhoneNumber} from "../../../utils/validators";
import {useGlobalContext} from "../../../contexts/GlobalContext";
import FloatingButton from "../../atomic/floating-button";

const Home = () => {
    const {slug} = useParams();
    const navigate = useNavigate();
    const {setPhoneNumber} = useGlobalContext();

    const {getInformation, responseGetApi} = useInfo();
    const {getBookingInformation, responseGetApi : getBookingInformationResponse} = useBooking();

    const {information} = useGlobalContext();
    const [enteredPhoneNumber, setEnteredPhoneNumber] = useState<string>("");

    useEffect(() => {
        if (slug) {
            getInformation(slug);
        }
    }, [slug]);

    const onClickSubmit = async () => {
        try {
            if (slug && enteredPhoneNumber) {
                if (isIranianPhoneNumber(enteredPhoneNumber)) {
                    setPhoneNumber(enteredPhoneNumber);
                    let hasBookings = await getBookingInformation(slug, enteredPhoneNumber);
                    if (hasBookings !== null) {
                        localStorage.setItem('phoneNumber', enteredPhoneNumber)
                        if (hasBookings) {
                            navigate('my-appointment-bookings')
                        } else {
                            navigate('appointment-booking')
                        }
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Ensure the value is a number or an empty string
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setEnteredPhoneNumber(value);
        }
    };

    return (
        <Layout>
            <div className={'flex flex-col items-center justify-center gap-8 my-auto'}>
                {responseGetApi?.isLoading ? <>loading</> : <img src={information?.imageUrl} alt="banner01" width="220"/>}
                <div className={'flex flex-col items-center justify-center gap-1/2'}>
                    <p className={'text-lg font-bold text-purple-dark'}>{responseGetApi?.isLoading ? 'loading' : `${information?.firstName} ${information?.lastName}`}</p>
                    <p className={'text-sm font-normal text-secondary text-[#AA5B7A]'}>نوبت‌دهی آنلاین خدمات زیبایی</p>
                </div>
                <div className={'flex flex-col items-center justify-center gap-5'}>
                    <p
                        className={'text-sm font-normal text-[#17191C] text-right w-full'}
                    >
                        برای استفاده از خدمات‌، شماره موبایل خود را وارد
                        کنید:
                    </p>
                    <Input
                        placeholder={'09 - -   - - -   - - - -'}
                        value={enteredPhoneNumber}
                        onChange={(e) => handleChange(e)}
                        maxLength={11}
                        direction={'ltr'}
                        className={'!text-left'}
                        type={'number'}
                        required={true}
                    />
                    <Button
                        title={'ثبت و ادامه'}
                        onClick={() => {
                            onClickSubmit()
                        }}
                        disabled={ enteredPhoneNumber.length != 11 }
                        leftIcon={<LeftArrowIcon/>}
                        loading={getBookingInformationResponse?.isLoading}
                    />
                </div>
                <FloatingButton
                    onClick={() => {
                        window.open(process.env.REACT_APP_TELEGRAM_LINK, '_blank');
                    }}
                    icon={<div className={'bg-[#E1EFFF] p-2 rounded-full shadow-xl'}><TelegramLogo/></div>}
                />
            </div>
        </Layout>
    )
}

export default Home;
