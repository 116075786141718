import React, {useEffect} from 'react';
import Button from "../../../atomic/button";
import Description from "../../../atomic/description";
import InfoItemCard from "./info-item-card";
import {
    InfoIcon,
    InstagramIcon,
    MapPinIcon,
    PhoneIcon,
    TelegramIcon,
} from "../../../../assets/icons";
import useInfo from "../../../../hooks/useInfo";
import {useParams} from "react-router-dom";

const AboutUsModal = ({onCloseModal}: {
    onCloseModal: () => void;
}) => {

    const {slug} = useParams();
    const {getAboutUsInformation, aboutUsInformation} = useInfo();
    useEffect(() => {
        if (slug)
            getAboutUsInformation(slug);
    }, [slug]);

    return (
        <>
            <div className={'flex flex-col mx-auto items-center text-right my-auto gap-4 p-5 pt-20 w-full md:w-1/3'}>
                <Description text={'شما می‌توانید مشخصات ما را در قسمت زیر مشاهده کنید:'}/>
                <InfoItemCard>
                    <div className={'flex justify-start gap-2'}>
                        <MapPinIcon color={'#92395c'}/>
                        <p className={'text-right'}>{aboutUsInformation?.address}</p>
                    </div>
                </InfoItemCard>
                <InfoItemCard>
                    <div className={'flex w-full justify-between items-center'}>
                        <PhoneIcon/>
                        <p className={'ltr text-left'}>{aboutUsInformation?.mobile}</p>
                    </div>
                </InfoItemCard>
                <InfoItemCard>
                    <div className={'flex w-full justify-between items-center'}>
                        <TelegramIcon/>
                        <p style={{direction: 'ltr'}}>
                            <a href={"https://t.me/"+aboutUsInformation?.telegram}>
                            @{aboutUsInformation?.telegram}
                            </a>
                            </p>
                    </div>
                </InfoItemCard>
                <InfoItemCard>
                    <div className={'flex w-full justify-between items-center'}>
                        <InstagramIcon/>
                        <p style={{direction: 'ltr'}}>
                        <a href={"https://www.instagram.com/"+aboutUsInformation?.instagram}>
                        @{aboutUsInformation?.instagram}
                        </a>
                        </p>
                    </div>
                </InfoItemCard>
                <InfoItemCard>
                    <div className={'flex flex-col'}>
                        <div className={'flex items-center gap-2'}>
                            <InfoIcon color={'#92395c'}/>
                            <p className={'text-[#7C808A] w-full md:max-w-[40vw]'}>
                                توضیحات
                            </p>
                        </div>
                        <p className={'text-[#17191C]'}>
                            {aboutUsInformation?.note}
                        </p>
                    </div>
                </InfoItemCard>
            </div>
            <div className={'flex justify-center items-center text-right gap-4 bg-gray-100 w-full py-4'}>
                <Button
                    title={'بستن'}
                    onClick={() => onCloseModal()}
                    variety={'secondary'}
                    customClassName={'h-[4vh]'}
                />
            </div>
        </>
    )
}

export default AboutUsModal;
