import React, {MouseEventHandler} from 'react';
import {LeftArrowIcon, Logo} from "../../../assets/icons";
import Button from "../../atomic/button";

interface ActionBarProps {
    price: number;
    hasPayment: boolean;
    onClickSubmitBookingButton: MouseEventHandler<HTMLButtonElement>;
    loading: boolean;
    disabled: boolean;
}

const ActionBar: React.FC<ActionBarProps> = (
    {price, hasPayment, onClickSubmitBookingButton, loading, disabled}
) => {
    return (
        <div
            className="fixed bottom-0 left-0 right-0 z-10 bg-purple-superLight w-full flex justify-center items-center gap-4 p-4">
            {hasPayment && <div className="flex flex-col items-center gap-1">
                <p className="text-[12px] text-gray-dark">مجموع پرداختی</p>
                <p className={`text-[14px] font-medium ${price === 0 ? 'text-gray-default' : 'text-green'}`}>
                    {price === 0 ? '0 تومان' : `${price.toLocaleString()} تومان`}
                </p>
            </div>}
            <Button
                title={hasPayment ? "پرداخت بیعانه و ثبت نوبت" : "ثبت نوبت"}
                leftIcon={<LeftArrowIcon/>}
                disabled={disabled}
                onClick={onClickSubmitBookingButton}
                loading={loading}
                customClassName={hasPayment ? '!min-w-[200px] mr-auto' : ''}
            />
        </div>
    );
};

export default ActionBar;
