import React from 'react';
import Input from "../../../atomic/input";
import Button from "../../../atomic/button";
import BookingInformationCard from "../../../composite/booking-information-card";
import RadioButtonGroup from "../../../composite/radiobutton-group";
import {CancelReservationReasonOptions} from "../../../../constants";

const CancelReservationRequestModal = ({
                                           onCloseModal,
                                           selectedBookingToCancel,
                                           onClickCancelBookingRequestButton,
                                           loading
                                       }: {
    onCloseModal: () => void;
    selectedBookingToCancel: BookingType | undefined;
    onClickCancelBookingRequestButton: (selected_booking: BookingType, selectedReason: string) => void;
    loading: boolean;
}) => {
    const [selectedReason, setSelectedReason] = React.useState<string>("");

    return (
        <>
            <div className={'flex flex-col items-center justify-center m-auto md:w-[30vw] gap-4 p-5'}>
                <p className={'text-gray-dark w-full text-right text-[16px] font-semibold'}>علت لغو نوبت خود را مشخص
                    کنید:</p>
                <RadioButtonGroup options={CancelReservationReasonOptions} onSelect={setSelectedReason}/>
                <BookingInformationCard resultStatus={true} mode={'canceling'} data={selectedBookingToCancel}/>

            </div>
            <div className={'flex justify-center items-center gap-4 bg-gray-100 w-full py-4'}>
                <div className={'flex gap-2 md:w-[30vw] justify-center'}>
                    <Button
                        title={'بستن'}
                        onClick={() => onCloseModal()}
                        variety={'secondary'}
                        customClassName={'!min-w-[163px] h-[4vh]'}
                    />
                    <Button
                        title={'لغو نوبت'}
                        disabled={selectedReason === ""}
                        onClick={
                            () => selectedBookingToCancel &&
                                onClickCancelBookingRequestButton(
                                    selectedBookingToCancel,
                                    selectedReason
                                )
                        }
                        customClassName={'!min-w-[163px] h-[4vh]'}
                        colorType={'reject'}
                        loading={loading}
                    />
                </div>
            </div>
        </>
    )
}

export default CancelReservationRequestModal;
