import React from "react";
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import Home from "./components/pages/home";
import NotFound from "./components/pages/404/NotFound";
import AppointmentBooking from "./components/pages/appointment-booking";
import MyAppointmentBookings from "./components/pages/my-appointment-bookings";
import BookingResult from "./components/pages/booking-result";
import Landing from "./components/pages/landing";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Landing/>}/>
                <Route path="/:slug" element={<Home/>}/>
                <Route path="/:slug/" element={<Home/>}/>
                <Route path="/:slug/home" element={<Home/>}/>
                <Route path="/:slug/appointment-booking/result" element={<BookingResult/>}/>
                <Route path="/:slug/appointment-booking" element={<AppointmentBooking/>}/>
                <Route path="/:slug/my-appointment-bookings" element={<MyAppointmentBookings/>}/>
                <Route path="/404" element={<NotFound/>}/>

                <Route path="*" element={<Navigate to="/404"/>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default App;
